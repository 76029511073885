import React from 'react';

export type AppContextValue = Readonly<{
  graphqlUrl: string,
  initialQueryState: object | null,
  statusCode?: number
}>;

export default React.createContext<AppContextValue>({
  graphqlUrl: '',
  initialQueryState: null,
});